
import { Component, Vue, Prop, Inject } from "vue-property-decorator";
import _ from "lodash";
import { DependencyContainer } from "tsyringe";
import { PROVIDER, SERVICES as S } from "@/config/literals";

import { IHydraulicSystemGroupService } from "@/services";

import { HydraulicSystemGroupInfos } from "@/domain";

@Component
export default class GroupAutocomplete extends Vue {
  @Inject(PROVIDER)
  container!: DependencyContainer;

  @Prop({ required: true })
  value!: string | null;

  @Prop({ required: false, default: null })
  groupIdToInclude!: string | null;

  @Prop({ required: false, default: false })
  includeAll!: boolean;

  get hydraulicSystemGroupService() {
    return this.container.resolve<IHydraulicSystemGroupService>(
      S.HYDRAULIC_SYSTEM_GROUP_SERVICE
    );
  }

  get filteredGroups() {
    const filtered: { id: string | null; name: string; monogram: string }[] =
      _.chain(this.groups)
        .filter((g) => !g.disabled || g.id == this.groupIdToInclude)
        .orderBy((o) => o.monogram, "asc")
        .map((g) => {
          return {
            id: g.id,
            name: g.name,
            monogram: g.monogram,
          };
        })
        .value();
    if (this.includeAll) {
      filtered.splice(0, 0, { id: null, name: "Toutes", monogram: "" });
    }
    return filtered;
  }

  groups: HydraulicSystemGroupInfos[] = [];
  loading: boolean = false;

  mounted() {
    this.fetch();
  }

  async fetch() {
    try {
      this.loading = true;
      this.groups = await this.hydraulicSystemGroupService.getMany({
        ids: [],
        includeDisabled: true,
        includeEnabled: true,
      });
    } finally {
      this.loading = false;
    }
  }
}
