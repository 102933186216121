
import { Component, Vue, Inject, Prop } from "vue-property-decorator";
import _ from "lodash";
import { DependencyContainer } from "tsyringe";
import { PROVIDER, SERVICES as S } from "@/config/literals";

import UpdateUi from "@/components/Core/Update/UpdateUI.vue";
import NuclearUnitAutocomplete from "@/components/General/Autocompletes/Localisation/NuclearUnitAutocompleteWrapper.vue";
import GroupAutocomplete from "@/components/General/Autocompletes/GroupAutocomplete.vue";

import { HydraulicSystemInfos } from "@/domain";

import { IHydraulicSystemService } from "@/services";

@Component({
  components: {
    UpdateUi,
    NuclearUnitAutocomplete,
    GroupAutocomplete,
  },
})
export default class SystemUpdate extends Vue {
  @Inject(PROVIDER)
  container!: DependencyContainer;

  @Prop({ required: true })
  item!: HydraulicSystemInfos;

  @Prop({ required: true })
  systems!: HydraulicSystemInfos[];

  get hydraulicSystemService() {
    return this.container.resolve<IHydraulicSystemService>(
      S.HYDRAULIC_SYSTEM_SERVICE
    );
  }

  get forbiddenNames(): string[] {
    return _.chain(this.systems)
      .filter((s) => s.nuclearUnitId == this.item.nuclearUnitId)
      .map((s) => s.name)
      .value();
  }

  get forbiddenTrigrams(): string[] {
    return _.chain(this.systems)
      .filter((s) => s.nuclearUnitId == this.item.nuclearUnitId)
      .map((s) => s.trigram)
      .value();
  }

  loading: boolean = false;
  name: string | null = null;
  trigram: string | null = null;
  groupId: string | null = null;

  mounted() {
    this.name = this.item.name;
    this.trigram = this.item.trigram;
    this.groupId = this.item.groupId;
  }

  async update() {
    try {
      this.loading = true;
      const systemUpdated = await this.hydraulicSystemService.update(
        this.item.id,
        {
          name: this.name!,
          trigram: this.trigram!,
          groupId: this.groupId!,
        }
      );
      this.$emit("update", systemUpdated);
    } finally {
      this.loading = false;
    }
  }

  isNameAllowed(name: string) {
    return (
      _.findIndex(
        this.forbiddenNames,
        (v) => v.toUpperCase() == name.toUpperCase()
      ) != -1 && this.item.name.toUpperCase() != name.toUpperCase()
    );
  }

  isTrigramAllowed(trigram: string) {
    return (
      _.findIndex(
        this.forbiddenTrigrams,
        (v) => v.toUpperCase() == trigram.toUpperCase()
      ) != -1 && this.item.trigram.toUpperCase() != trigram.toUpperCase()
    );
  }
}
