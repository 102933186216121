var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('update-ui',{attrs:{"loading":_vm.loading,"title":`Édition d'un système (${_vm.item.trigram})`},on:{"close":function($event){return _vm.$emit('close')},"update":_vm.update},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('v-row',[_c('v-col',{attrs:{"cols":"7"}},[_c('v-text-field',{attrs:{"label":"Nom système","rules":[
            (v) => !!v || 'Ce champ est requis',
            (v) =>
              (!!v && !_vm.isNameAllowed(v)) ||
              'Ce nom est déjà existant dans la tranche',
          ]},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-text-field',{attrs:{"label":"Trigramme système","rules":[
            (v) => !!v || 'Ce champ est requis',
            (v) =>
              (!!v && !_vm.isTrigramAllowed(v)) ||
              'Ce trigramme est déjà existant dans la tranche',
          ]},model:{value:(_vm.trigram),callback:function ($$v) {_vm.trigram=$$v},expression:"trigram"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('nuclear-unit-autocomplete',{attrs:{"value":_vm.item.nuclearUnitId,"include-disabled":true,"disabled":""}})],1)],1),_c('v-row',[_c('v-col',[_c('group-autocomplete',{attrs:{"group-id-to-include":_vm.item.groupId,"rules":[(v) => !!v || 'Ce champ est requis']},model:{value:(_vm.groupId),callback:function ($$v) {_vm.groupId=$$v},expression:"groupId"}})],1)],1)]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }